<template lang="pug">
.qr-print
  .facility-title
    span {{ data.facility_title }}
  .logo
    img(:src="require(`../../../../../../../assets/images/amelia_logo.jpg`)")
  .serial_number
    span {{ "Инв. № " + data.description }}
  .qr(v-if="data.qr", v-html="data.qr")
</template>

<script>
export default {
  components: {},

  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      data: this.parentData.data,
    };
  },

  computed: {},

  methods: {},
};
</script>
