import Vue from "vue";

Vue.mixin({
  methods: {
    specificEntityExcel(attr) {
      let fn;
      let params = {};
      params["report_params"] = {};
      params["report_params"]["filters"] = {};

      if (attr.export_type) {
        params["report_type"] = attr.export_type;
      }

      if (attr.row_id) {
        params["report_params"]["row_id"] = attr.row_id;
      }

      if (attr.report_class) {
        params["report_params"]["report_class"] = attr.report_class;
      }

      if (attr.report_namespace) {
        params["report_params"]["report_namespace"] = attr.report_namespace;
      }

      if (attr.sti_type) {
        params["report_params"]["filters"]["type"] = attr.sti_type;
      }

      // this.loading = true

      this.report_path = this.$store.state.paths["report"];

      this.loadReport(params);
    },
  },
});
