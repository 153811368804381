import Vue from "vue";
import axios from "axios";
import store from "../store/index";
import modules from "./modules/index";

const base_url = process.env.VUE_APP_BACKEND_URL || process.env.BASE_URL;
console.log("baseURL", base_url);

const adapter = axios.create({
  baseURL: base_url,
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
  },
});

function addAuthHeader(adapter) {
  return (adapter.defaults.headers.common["Authorization"] = "Bearer " + store.state.account.auth_token);
}

const backend = {
  index: (url, params) => {
    addAuthHeader(adapter);
    return adapter.get(url, params);
  },
  show: (url, id, params) => {
    addAuthHeader(adapter);
    return adapter.get(url + "/" + id, params);
  },
  create: (url, id = null, params) => {
    addAuthHeader(adapter);
    params = modules["formData"].jsonToFormData(params);
    return adapter.post(url, params, {
      headers: modules["formData"].headers,
    });
  },
  update: (url, id, params) => {
    addAuthHeader(adapter);
    params = modules["formData"].jsonToFormData(params);
    return adapter.patch(url + "/" + id, params, {
      headers: modules["formData"].headers,
    });
  },
  destroy: (url, id) => {
    addAuthHeader(adapter);
    return adapter.delete(url + "/" + id);
  },
  sign_in: (url, params) => {
    addAuthHeader(adapter);
    return adapter.post(url, params);
  },
  sign_out: url => {
    return adapter.delete(url);
  },
  recovery_password: (url, params) => {
    return adapter.post(url, params);
  },
  reset_password: (url, params) => {
    return adapter.put(url, params);
  },
  update_or_create: (url, params) => {
    addAuthHeader(adapter);
    params = modules["formData"].jsonToFormData(params);
    return adapter.put(url + "/update_or_create", params, {
      headers: modules["formData"].headers,
    });
  },
  collection: (url, params) => {
    addAuthHeader(adapter);
    params = modules["formData"].jsonToFormData(params);
    return adapter.post(url, params, {
      headers: modules["formData"].headers,
    });
  },
  destroy_attachment: (url, id, params) => {
    addAuthHeader(adapter);
    return adapter.put(`${url}/destroy_attachment/${id}`, params);
  },
};

Vue.prototype.$base_url = base_url;
Vue.prototype.$backend = backend;
