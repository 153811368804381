import Vue from "vue";
import VueRouter from "vue-router";
import Alarm from "../components/shared/Alarm";
import Main from "../components/shared/Main";

Vue.use(VueRouter);

let baseRoutes = [
  {
    name: "Alarm",
    path: "/alarm",
    component: Alarm,
  },
];

export const router = new VueRouter({
  mode: "history",
  hashbang: false,
  baseRoutes,
});

router.beforeResolve((to, from, next) => {
  if (to.path === "/alarm") {
    next();
  } else {
    next({ path: "/alarm" });
  }
});
