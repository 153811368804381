import Vue from "vue";
import VueI18n from "vue-i18n";

const messages = {
  ru: {
    message: {
      zeroBytes: "0 байт",
      bytes: "байт",
      kb: "кб",
      mb: "мб",
      gb: "гб",
      tb: "тб",
      pb: "пб",
      report: "Ваш отчет готов! Скачайте его, нажав на иконку ниже:",
      confidentialityPolicy: "Политика обработки персональных данных",
    },
  },
  en: {
    message: {
      zeroBytes: "0 bytes",
      bytes: "bytes",
      kb: "kb",
      mb: "mb",
      gb: "gb",
      tb: "tb",
      pb: "pb",
      report: "Your report is ready! Download it by clicking on the icon below:",
      confidentialityPolicy: "Personal data processing policy",
    },
  },
};

Vue.use(VueI18n);

// Create VueI18n instance with options
export default new VueI18n({
  locale: "ru",
  messages,
});
