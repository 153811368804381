import Vue from "vue";
import "../assets/styles/quasar.styl";
import "../shared/quasar";
import Main from "../components/shared/Main";
import store from "../store/index.js";
import { router } from "../routing/router.js";
import "echarts/lib/chart/gauge";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/line";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "../api";
import "../plugins/actioncable-vue";
import "../plugins/vue2-editor";
import "../plugins/vue-infinite-scroll";
import "../plugins/vue-inline-svg";
import "../plugins/vue-datetime";
import "../plugins/vue-observe-visibility";
import "../plugins/vue-moment";
import "../mixins";
import "../plugins/vue-awesome-swiper";
import "../plugins/vue-print";
import "../plugins/vue-apexcharts";
import "../plugins/q-icon-picker";
import "../plugins/highcharts-vue";
import i18n from "../plugins/vue-18n";

new Vue({
  render: h => h(Main),
  i18n,
  store,
  router,
}).$mount("#vue-app");
