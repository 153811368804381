import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

import { account } from "./modules/account";
import { theme } from "./modules/theme";
import { issues_tabs } from "./modules/issues_tabs";
import { dashboard } from "./modules/dashboard";
import { grid } from "./modules/grid";
import { locales } from "./modules/locales";

const persistedStates = createPersistedState({
  paths: ["account", "theme", "issues_tabs"],
});

export default new Vuex.Store({
  state: {
    paths: {
      sign_in: "/api/v3/auth/login",
      sso: "/api/v3/saml/sso",
      default_locale: "/api/v3/auth/default_locale",
      login_locales: "/api/v3/auth/login_locales",
      default_locales: "/api/v3/auth/default_locales",
      reset_password: "/api/v3/reset_password",
      issue: "/api/v3/issues",
      archive: "/api/v3/issues/archive",
      post: "/api/v3/posts",
      facility: "/api/v3/facilities",
      service: "/api/v3/services",
      work_category: "/api/v3/work_categories",
      company: "/api/v3/companies",
      building: "/api/v3/buildings",
      floor: "/api/v3/floors",
      room: "/api/v3/rooms",
      room_type: "/api/v3/room_types",
      sign_out: "/api/v3/auth/login",
      dashboard_data: "/api/v3/dashboards/data",
      dashboard: "/api/v3/dashboards",
      user: "/api/v3/users",
      work_group: "/api/v3/work_groups",
      work: "/api/v3/works",
      measure_unit: "/api/v3/measure_units",
      document: "/api/v3/documents",
      material: "/api/v3/materials",
      navbar: "/api/v3/navbar",
      notification_rule: "/api/v3/notification_rules",
      fullcalendar: "/api/v3/fullcalendar/fullcalendar_data",
      comment: "/api/v3/comments",
      consumption: "/api/v3/consumptions",
      completed_work: "/api/v3/completed_works",
      issue_children: "/api/v3/issue_children",
      contract: "/api/v3/contracts",
      product: "/api/v3/products",
      purchase: "/api/v3/purchases",
      ppr_equipment: "/api/v3/ppr/equipments",
      ppr_calendar: "/api/v3/ppr/calendar",
      ppr_comment: "/api/v3/ppr/comments",
      ppr_issue: "/api/v3/ppr/issues",
      ppr_archive: "/api/v3/ppr/issues/archive",
      ppr_group_system: "/api/v3/ppr/group_systems",
      ppr_system: "/api/v3/ppr/systems",
      ppr_work: "/api/v3/ppr/works",
      ppr_frequency_work: "/api/v3/ppr/frequency_works",
      ppr_work_equipment: "api/v3/ppr/work_equipments",
      ppr_work_group: "/api/v3/ppr/work_groups",
      employee: "/api/v3/employees",
      illness_status: "/api/v3/illness_statuses",
      employee_status: "/api/v3/employee_statuses",
      report: "/api/v3/reports",
      hidden_message: "/api/v3/app_settings/grid_hidden_message",
      checklist: "/api/v3/checklists",
      tarifier: "/api/v3/tarifiers",
      additional_tarifier: "/api/v3/additional_tarifiers",
      issue_tarifier: "/api/v3/issue_tarifiers",
      department: "/api/v3/departments",
      marketing_issue: "/api/v3/marketing/issues",
      marketing_archive: "/api/v3/marketing/issues/archive",
      budget_tables: "/api/v3/budget_tables",
      budget_cost_item: "api/v3/budget/cost_items",
      budget_building_group: "api/v3/budget/building_groups",
      division: "/api/v3/divisions",
      cleaning_group: "/api/v3/cleaning_groups",
      cleaning_journal: "/api/v3/cleaning_journals",
      cleaning_act: "/api/v3/cleaning_acts",
      cleaning_act_contract_number: "/api/v3/cleaning_act/contract_numbers",
      cleaning_tariff: "/api/v3/cleaning_tariffs",
      order_type: "api/v3/order_types",
      financial_management_department: "api/v3/financial_management_departments",
      purchase_group: "api/v3/purchase_groups",
      longterm_contract: "api/v3/longterm_contracts",
      daily_kafka_message_statistic: "api/v3/daily_kafka_message_statistic",
    },

    grids: [
      "issues",
      "archive",
      "posts",
      "facilities",
      "buildings",
      "floors",
      "rooms",
      "room_types",
      "users",
      "work_groups",
      "works",
      "measure_units",
      "companies",
      "documents",
      "services",
      "work_categories",
      "materials",
      "notification_rules",
      "comments",
      "instructions",
      "contracts",
      "employees",
      "illness_statuses",
      "employee_statuses",
      "tarifiers",
      "budget_tables",
      "budget_cost_items",
      "budget_building_groups",
      "divisions",
      "cleaning_groups",
      "cleaning_journals",
      "cleaning_acts",
      "cleaning_tariffs",
      "order_types",
      "financial_management_departments",
      "purchase_groups",
      "longterm_contracts",
      "daily_kafka_message_statistic",
    ],

    additional_grids: ["archive", "comments", "dashboards", "checklists", "issue_tarifiers"],

    fields_with_options: ["select", "select_multiple", "radio_collection"],

    handle_by_available_transitions_grids: [
      "issues",
      "archive",
      "ppr_issues",
      "ppr_archive",
      "marketing_issues",
      "marketing_archive",
    ],

    // initial_grid_fields: {
    //   form: {},
    //   query: '',
    //   filters: {},
    //   except_filters: {}
    // },

    issues_active_states: [
      "initial",
      "requesting_declarer",
      "accepted",
      "estimating",
      "manager_checked",
      "estimate_checked",
      "arriving",
      "arrived",
      "arriving_checked",
      "estimate_approved",
      "manager_approved",
      "returned",
      "returned_by_manager",
      "work_verified",
      "manager_confirmed",
      "paid",
      "pay_processing",
      "approved",
      "reworked",
      "processing",
      "checking_on",
      "paused",
      "done",
      "no_tech_insp",
      "sap_sending",
      "order_generation",
      "bh_approving",
      "sap_reapproving",
      "for_deleting",
      "payment_via_usnz",
      "order_making_by_usnz",
      "urgent_accepted",
      "urgent_processing",
      "urgent_done",
      "order_reapproved",
    ],

    issues_archive_states: ["rejected", "closed", "closed_marketing"],

    ppr: {
      grids: [
        "calendar",
        "issues",
        "archive",
        "group_systems",
        "systems",
        "equipments",
        "works",
        "frequency_works",
        "work_groups",
      ],
    },

    marketing: {
      grids: ["issues", "archive"],
    },
    notifications: [{ title: "", body: "", id: -1 }],
    unresettable_filters: {},
  },
  mutations: {
    addNotification(state, notification) {
      if (notification.id && state.notifications.findIndex(elem => elem.id === notification.id) === -1) {
        state.notifications.push(notification);
        // console.log(state.notifications);
      }
    },

    updateUnresettableFilters(state, list) {
      list.forEach(data => Vue.set(state["unresettable_filters"], data.name, data.value));
    },
  },
  actions: {},
  modules: {
    account,
    locales,
    theme,
    issues_tabs,
    dashboard,
    grid,
  },
  plugins: [persistedStates],
});
