<template lang="pug">
.hidden-tabs-message(v-show="hide_table")
  span {{ message }}
</template>

<script>
export default {
  components: {},

  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      hide_table: this.parentData.hide_table,
      message: "",
    };
  },

  computed: {},

  created() {
    this.$root.$on("reload-hidden-message", () => {
      if (this.hide_table) {
        this.loadHiddenMsg();
      }
    });
  },

  methods: {
    loadHiddenMsg(params = {}) {
      this.$backend
        .index(this.$store.state.paths["hidden_message"], { params: params })
        .then(({ data }) => {
          this.message = data;
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        });
    },

    hideTable(val) {
      this.hide_table = val;
      if (this.hide_table) {
        this.loadHiddenMsg();
      }
    },
  },
};
</script>

<style lang="scss"></style>
