<template lang="pug">
div
  .form-field-label
    span {{ label }}
    span.form-field-require-tag(v-show="required", style="color: red", :title="notifies.required_field")
      | *

  div(:class="[currentFieldIsInvalid ? main_class + ' valid-error' : main_class]")
    q-icon.cancel-editor-field(name="cancel", v-if="currentField", @click.stop="resetField()")

    vue-editor(
      v-model="currentField",
      @blur="onEditorBlur($event)",
      @focus="onEditorFocus($event)",
      @ready="onEditorReady($event)",
      @text-change="onEditorChange($event)",
      @editorToolbar="customToolbar"
    )

  span.valid-error-message(v-if="currentFieldIsInvalid") {{ valid_error_message }}
</template>

<script>
import fullToolbar from "../../../../plugins/vue2-editor/fullToolbar.js";

export default {
  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      method: this.parentData.method,
      method_limit: this.parentData.method_limit,
      grid: this.parentData.grid,
      value: this.parentData.data[0].value,
      label: this.parentData.data[0].label,
      name: this.parentData.data[0].name,
      depend_from: this.parentData.data[0].depend_from || {},
      required: this.parentData.data[0].require,
      watch: this.parentData.data[0].watch || {},
      valid_error_message: null,
      main_class: "form-field form-editor",

      customToolbar: fullToolbar,
    };
  },

  computed: {
    currentField: {
      get() {
        let form_field = this.currentForm[this.name];
        if (form_field) {
          return form_field["field"];
        } else {
          return undefined;
        }
      },
      set(value) {
        let result = {};
        if (value && value.length > 0) {
          result["field"] = value;
          result["invalid"] = this.invalid(value);
        } else {
          result["invalid"] = this.invalid();
        }
        this.$store.commit("updateFormField", { grid_name: this.grid, field: this.name, value: result });
        if (!result["invalid"]) {
          this.$store.commit("resetFormFieldValue", {
            grid_name: this.grid,
            field: "invalid_fields",
            value: this.name,
          });
        }
      },
    },

    fieldDependency() {
      return this.checkDependency();
    },
  },

  watch: {
    fieldDependency(newVal, oldVal) {},
  },

  created() {
    this.watchSimpleFieldParents();
  },

  beforeMount() {
    this.$store.commit("createFormField", { grid_name: this.grid, field: this.name });
    this.setField(this.value);
  },

  methods: {
    onEditorBlur(quill) {
      // console.log('editor blur!', quill)
    },
    onEditorFocus(quill) {
      // console.log('editor focus!', quill)
    },
    onEditorReady(quill) {
      // console.log('editor ready!', quill)
    },
    onEditorChange({ quill, html, text }) {
      // console.log('editor change!', quill, html, text)
    },

    setField(val) {
      this.currentField = val;
    },

    resetField() {
      this.currentField = "";
    },

    invalid(val = undefined) {
      if (this.required) {
        if (val && val.length > 0) {
          this.valid_error_message = null;
          return false;
        } else {
          this.valid_error_message = this.notifies.not_empty;
          return true;
        }
      } else {
        this.valid_error_message = null;
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/styles/forms/fields/editor";
</style>
