import requestDeclarerTransition from "./default_transition";
import returnToWorkTransition from "./default_transition";
import emergencyRequestDeclarerTransition from "./default_transition";
import emergencyReturnToWorkTransition from "./default_transition";
import acceptTransition from "./default_transition";
import finishTransition from "./default_transition";
import processTransition from "./default_transition";
import closeTransition from "./default_transition";
import closeEmergencyTransition from "./default_transition";
import closeMarketingTransition from "./default_transition";
import changeExecutorsTransition from "./default_transition";
import changeEstimatorsTransition from "./default_transition";
import changeManagersTransition from "./default_transition";
import changeUsnzsTransition from "./default_transition";
import changeServiceTransition from "./default_transition";
import rejectTransition from "./default_transition";
import returnTransition from "./default_transition";
import checkOnTransition from "./default_transition";
import reopenTransition from "./default_transition";
import reworkTransition from "./default_transition";
import pauseTransition from "./default_transition";
import restoreTransition from "./default_transition";
import addUserTransition from "./default_transition";
import addWorkCategoryTransition from "./default_transition";
import addBuildingTransition from "./default_transition";
import estimateTransition from "./default_transition";
import managerCheckTransition from "./default_transition";
import estimateCheckTransition from "./default_transition";
import estimateDisapproveTransition from "./default_transition";
import estimateApproveTransition from "./default_transition";
import managerDisapproveTransition from "./default_transition";
import managerApproveTransition from "./default_transition";
import returnForEstimate from "./default_transition";
import payProcessTransition from "./default_transition";
import paidTransition from "./default_transition";
import approveTransition from "./default_transition";
import arrivingTransition from "./default_transition";
import arrivedTransition from "./default_transition";
import arrivingCheckedTransition from "./default_transition";
import workVerifyTransition from "./default_transition";
import managerConfirmTransition from "./default_transition";
import addPprSystem from "./default_transition";
import withoutTechInspection from "./without_tech_inspection";
import rejectAnyTransition from "./default_transition";
import sendToSapTransition from "./default_transition";
import orderGenerateTransition from "./default_transition";
import bhApproveTransition from "./default_transition";
import sapReapproveTransition from "./default_transition";
import forDeleteTransition from "./default_transition";
import returnForSapReapproveTransition from "./default_transition";
import returnToManagerApprovedTransition from "./default_transition";
import returnToWorkVerifiedTransition from "./default_transition";
import payViaUsnzTransition from "./default_transition";
import makeOrderByUsnzTransition from "./default_transition";
import approveOrderMakingByUsnzTransition from "./default_transition";
import managerCheckFromReturnedTransition from "./default_transition";
import acceptUrgentTransition from "./default_transition";
import processUrgentTransition from "./default_transition";
import finishUrgentTransition from "./default_transition";
import confirmCompletionTransition from "./default_transition";

export default {
  request_declarer: requestDeclarerTransition,
  emergency_request_declarer: emergencyRequestDeclarerTransition,
  return_to_work: returnToWorkTransition,
  emergency_return_to_work: emergencyReturnToWorkTransition,
  accept: acceptTransition,
  finish: finishTransition,
  process: processTransition,
  reject: rejectTransition,
  close: closeTransition,
  close_emergency: closeEmergencyTransition,
  close_marketing: closeMarketingTransition,
  return: returnTransition,
  restore: restoreTransition,
  executor_changing: changeExecutorsTransition,
  add_managers: changeManagersTransition,
  add_usnzs: changeUsnzsTransition,
  add_estimators: changeEstimatorsTransition,
  service_changing: changeServiceTransition,
  check_on: checkOnTransition,
  reopen: reopenTransition,
  rework: reworkTransition,
  pause: pauseTransition,
  add_user: addUserTransition,
  add_work_category: addWorkCategoryTransition,
  add_building: addBuildingTransition,
  estimate: estimateTransition,
  manager_check: managerCheckTransition,
  estimate_check: estimateCheckTransition,
  estimate_disapprove: estimateDisapproveTransition,
  estimate_approve: estimateApproveTransition,
  manager_approve: managerApproveTransition,
  manager_disapprove: managerDisapproveTransition,
  return_for_estimate: returnForEstimate,
  pay_process: payProcessTransition,
  work_verify: workVerifyTransition,
  manager_confirm: managerConfirmTransition,
  paid: paidTransition,
  approve: approveTransition,
  arriving: arrivingTransition,
  arrived: arrivedTransition,
  arriving_checked: arrivingCheckedTransition,
  add_ppr_system: addPprSystem,
  without_tech_inspection: withoutTechInspection,
  reject_any: rejectAnyTransition,
  order_generate: orderGenerateTransition,
  send_to_sap: sendToSapTransition,
  bh_approve: bhApproveTransition,
  sap_reapprove: sapReapproveTransition,
  for_delete: forDeleteTransition,
  return_for_sap_reapprove: returnForSapReapproveTransition,
  return_to_manager_approved: returnToManagerApprovedTransition,
  return_to_work_verified: returnToWorkVerifiedTransition,
  pay_via_usnz: payViaUsnzTransition,
  make_order_by_usnz: makeOrderByUsnzTransition,
  approve_order_making_by_usnz: approveOrderMakingByUsnzTransition,
  manager_check_from_returned: managerCheckFromReturnedTransition,
  accept_urgent: acceptUrgentTransition,
  process_urgent: processUrgentTransition,
  finish_urgent: finishUrgentTransition,
  confirm_completion: confirmCompletionTransition,
};
