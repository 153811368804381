export default {
  rejected() {
    // console.log("rejected");
  },

  connected() {
    // console.log("connected");
  },

  received(data) {
    if (data) {
      this.$q.notify(data.title);
      this.$store.commit("addNotification", data);
    }
  },
};
