<template lang="pug">
#main(:class="[expand_class ? self_class + ' ' + expanded_class : self_class]")
  #modal-background

  .main-component
    .login-container
      .service-moved
        .service-moved__wrapper
          p Уважаемые коллеги,

          p Информируем вас, что сервис Амелия обновляется до новой версии. В этой связи сервис будет недоступен с
            b &nbsp08:00 27.05
            span &nbspпо
            b &nbsp15:00 28.05
            span &nbsp(МСК).

          p Обновлённая Амелия будет доступна по ссылке:&nbsp
            a(href="https://newamelia.mvideo.ru/") https://newamelia.mvideo.ru/
            span &nbspc 15:00 28.05.2024 (MCK).

          ul 
            li Директора магазинов: по вопросам эксплуатации просьба обращаться к вашему менеджеру эксплуатации/ПБ/ИРК/клининг.
            li Сотрудники подрядных организаций: взаимодействие по заявкам осуществляется через ответственных менеджеров эксплуатации/ПБ/ИРК/клининг.

          div Более подробная информация дана в коммуникации (ЭМ.Лайф или почта).

      .login-component
        video(autoplay, muted, loop)
          source(type="video/mp4", :src="require('../../assets/videos/amelia-background-video.mp4')")

        .login-background

        .q-pa-md.row.items-start.justify-center
          #login-card
            .login-logo.row.justify-center(style="margin-top: 150px !important")
              inline-svg(:src="require('../../assets/icons/login/login-logo.svg')")

            .login-title.row.justify-center(style="margin-top: 150px !important")
              span В связи с проведением
              span технических работ
              span cайт временно не доступен
</template>

<script>
import Navbar from "./Navbar";
import reportLinkArea from "./helpers/reportLinkArea";
import channels from "@/components/shared/channels";

export default {
  channels: channels,

  components: {
    Navbar,
    reportLinkArea,
    channels,
  },
  data: function () {
    return {
      navbar_loading: false,
      main_loading: false,
      self_class: "main",

      expand_class: false,
      expanded_class: "",

      intervals: [],
      report: {},
      report_modal: false,

      check_auth_loading: false,
    };
  },

  computed: {
    allDataLoaded() {
      let main_loading = this.main_loading;
      return (
        (!this.isAuthenticated() && !main_loading) ||
        (this.isAuthenticated() && this.theme && !this.navbar_loading && !main_loading)
      );
    },
  },

  watch: {
    current_user(newVal, oldVal) {
      // console.log('main current_user newVal', newVal)
      if (newVal) {
        this.setTheme(newVal.theme);
      }
    },

    check_auth_loading(newVal, oldVal) {
      if (newVal === false) {
        if (this.current_user) {
          this.setTheme();
        }
        this.reportLogic();
      }
    },
  },

  created(params = {}) {
    this.loadDefaultLocale();
    this.loadDefaultLocalesData();

    setTimeout(() => {
      if (this.$route.query.adfs_login_token) {
        params["user"] = { adfs_login_token: this.$route.query.adfs_login_token };
        this.signIn(params);
        this.check_auth_loading = false;
      } else {
        this.check_auth();
      }
    }, 500);
  },

  beforeDestroy() {
    this.destroyIntervals();
  },

  mounted() {
    this.$cable.subscribe({ channel: "NotificationsChannel", token: this.auth_token });
    this.$cable.subscribe({ channel: "AlertsChannel", token: this.auth_token });
  },

  methods: {
    check_auth() {
      this.$backend
        .index(this.$store.state.paths.user + "/current_theme", { params: {} })
        .then(({ data }) => {
          this.check_auth_loading = false;
        })
        .catch(error => {
          this.check_auth_loading = true;

          if (error.response) {
            this.reLogin(error.response.status);

            setTimeout(() => {
              this.check_auth_loading = false;
            }, 1500);
          }
        });
    },

    expandClass(data) {
      if (data) {
        this.expand_class = data.val;
        this.expanded_class = data.class;
      } else {
        this.expand_class = false;
        this.expanded_class = "";
      }
    },

    setNavbarLoading(val) {
      setTimeout(() => {
        this.navbar_loading = val;
      }, 1500);
    },

    setMainLoading(val) {
      // setTimeout(() => {
      this.main_loading = val;
      // }, 1000)
    },

    isAuthenticated() {
      return false;
    },

    reportLogic() {
      this.$root.$on("init-report-intervals", data => {
        localStorage.setItem("report_id_waiting", data.id);
        this.initReportIntervals(data.id);
      });

      this.$root.$on("show-report-modal", data => {
        this.report = data.report;
        this.report_modal = data.value;
      });

      if (this.report_id_waiting) {
        this.initReportIntervals(this.report_id_waiting);
      }
    },

    initReportIntervals(id) {
      this.destroyIntervals();
      this.intervals = [
        setInterval(() => {
          this.checkReportState(id);
        }, 10000),
      ];
    },

    checkReportState(id) {
      this.$backend
        .show(this.$store.state.paths["report"], id, { params: {} })
        .then(({ data }) => {
          if (data["expired"] && data["link"]) {
            this.destroyIntervals();
            localStorage.removeItem("report_id_waiting");
            this.report = data;
            this.report_modal = true;
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
          this.destroyIntervals();
          localStorage.removeItem("report_id_waiting");
          this.$q.notify(this.notifies.report_error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/main.scss";
@import "../../assets/styles/login.scss";

.service-moved {
  background-color: #d53032;
  color: white;
  padding: 25px;
  position: relative;
  z-index: 10;
  font-size: 20px;
  font-weight: 500;

  ul {
    margin-left: 15px;
    margin-bottom: 16px;
  }

  &__wrapper {
    width: 1050px;
    margin: auto;
  }

  &--main {
    border-radius: 16px;
    margin-top: 20px;

    ul {
      margin: 0 0 16px 15px;
    }
  }

  &__main-title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-weight: bold;
  }

  &__additional-info {
    display: flex;
    justify-content: space-between;
    gap: 14px;
    margin-top: 10px;

    &__block {
      background: #ffffff26;
      padding: 5px 10px;
      border-radius: 8px;
    }
  }
}
</style>
