<template lang="pug">
.existing-files-container
  .existing-file-item(v-for="file in files")
    .full-height(v-if="isImage(file)")
      img.image-item(:src="file.url")

    .doc-item(v-else)
      inline-svg(:src="require('../../../assets/icons/doc.svg')")

    .item-details(v-if="file")
      .item-caption {{ file.file_name }}
        q-tooltip(anchor="top middle", self="bottom middle") {{ file.file_name }}
      div 
        a.item-link(:href="file.url", target="_blank") {{ download_label }}
        span.item-size.q-pl-xs ({{ fileSize(file) }})
</template>

<script>
import { BytesFormatter } from "../../../services/bytesFormatter";

export default {
  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      path: this.parentData.path,
      confirm_modal: false,
      confirm_message: "",
      destroy_data: undefined,
    };
  },

  computed: {
    field_locales() {
      return this.locales.file_field[this.current_locale];
    },

    row() {
      return this.parentData.row;
    },

    files() {
      return this.parentData.files;
    },

    download_label() {
      return this.parentData.download_label;
    },
  },

  methods: {
    onDelete(file) {
      this.destroy_data = file;
      this.confirm_message = this.field_locales.confirm_remove_file;
      this.confirm_modal = true;
    },

    confirmResult(val) {
      if (val) {
        this.$backend
          .destroy_attachment(this.path, this.row.id, { asset_id: this.destroy_data.id })
          .then(({ data }) => {
            this.confirm_modal = false;
            this.destroy_data = undefined;
            this.$root.$emit("load-show-data", data);
            this.$root.$emit("reload-show-tab", "");
            this.$emit("close-files-modal");
          })
          .catch(error => {
            console.log(error);
            if (error.response) {
              this.reLogin(error.response.status);
            }
            this.$q.notify(this.notifies.error_ask_admin);
          });
      } else {
        this.confirm_modal = false;
        this.destroy_data = undefined;
      }
    },

    isImage(value) {
      if (value) {
        return ["image/jpeg", "image/png"].includes(value.content_type);
      } else {
        return false;
      }
    },

    fileSize(file) {
      const bytesFormatter = new BytesFormatter(file.file_size);

      return bytesFormatter.convert();
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/forms/fields/file";

.existing-files-container {
  %rounded-item {
    border-radius: 10px;
  }

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  row-gap: 5em;
  margin-bottom: 5em;
  column-gap: 2.29%; // hardcoded to look good in specific size - 1000px

  .existing-file-item {
    @extend %rounded-item;

    background: var(--file-card-color);
    width: 12em;
    height: 5.7em;
    border: 1px solid #d0cece;
    border-radius: 10px;

    .image-item {
      @extend %rounded-item;

      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .doc-item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .item-details {
      text-align: center;
      padding-top: 0.8em;
    }

    .item-caption {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-transform: capitalize;
      color: var(--field-input-color);
    }

    .item-link {
      color: #5b7aff;
      text-decoration: none;
    }

    .item-size {
      color: #78839c;
    }
  }
}
</style>
