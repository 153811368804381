import Vue from "vue";

Vue.mixin({
  computed: {
    login_locales() {
      return this.locales.login[this.current_locale];
    },
  },

  methods: {
    signIn(params) {
      this.$emit("set-loading", true);

      this.$backend
        .sign_in(this.$store.state.paths["sign_in"], params)
        .then(response => {
          this.$store.commit("logIn", { user: response.data.user, token: response.data.token });
          this.$router.push({ name: "Dashboard" });
        })
        .catch(error => {
          if (error.response && [401, 404].includes(error.response.status)) {
            this.reLoginRun(this.login_locales["login_form.notifies.invalid_login"]);
          } else if (error.response && error.response.status === 403) {
            this.reLoginRun(this.login_locales["login_form.notifies.blocked_user"]);
          }
        });
    },

    reLoginRun(msg = undefined) {
      this.$q.notify({
        badgeStyle: "display: none",
        message: msg || this.notifies.session_has_over,
      });
      this.$store.commit("logOut");

      if (this.$route.path === "/login") {
        setTimeout(() => this.$emit("set-loading", false), 500);
      } else {
        this.$router.replace({ name: "Login" });
      }
    },

    reLogin(status, msg = undefined) {
      if (status && status === 401) {
        this.reLoginRun(msg);
      }
    },
  },
});
