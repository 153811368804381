<template lang="pug">
.show-card-actions
  grid-actions(
    v-for="(handler, i) in data.handlers",
    :key="handler.name",
    ref="grid-actions",
    @refresh-table="refreshTable",
    @open-form="openForm",
    :parentData="{ index: i + 1, actions: getHandleActions(handler.name), row: row, grid: grid, grid_key: grid_key, path: path, transition_key: transition_key, btn_label: handler.label, default_class: 'grid-actions ' + handler.name, actions_icon_path: 'show/actions/' + handler.icon }"
  )

  .button-wrapper(v-for="(button, i) in data.buttons", :key="button.name", :style="button.style.wrapper")
    q-btn.show-card-action(flat, no-caps, @click="onClickFn(button.onclick)", :style="button.style.button")
      template(v-if="button.icon && button.icon.length > 0", slot="default")
        inline-svg(
          :class="[button.name + '-icon']",
          :style="button.style.icon",
          :src="require(`../../../assets/icons/show/actions/${button.icon}`)"
        )
      template(v-if="button.label && button.label.length > 0", slot="default")
        span.action-label {{ button.label }}
</template>

<script>
import gridActions from "../grid/gridActions";

export default {
  components: {
    gridActions,
  },

  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      data: this.parentData.data,
      path: this.parentData.path,
      // row: this.parentData.row,
      transition_key: this.parentData.transition_key,
      grid: this.parentData.grid,
      grid_key: this.parentData.grid_key,
    };
  },

  computed: {
    row() {
      return this.parentData.row;
    },
  },

  methods: {
    // refreshRow(row) {
    //   this.row = row
    //   this.$refs['grid-actions'][0].refreshRow(row)
    // },

    onClickFn(onclick) {
      if (onclick) {
        this[onclick.fn_name](onclick.attr);
      }
    },

    refreshTable(data) {
      this.$emit("refresh-table", data);
    },

    openForm(data) {
      this.$emit("open-form", data);
    },

    getHandleActions(action, result = {}) {
      result = Object.keys(this.currentGrid.actions)
        .filter(key => key === action)
        .reduce((r, key) => {
          r[key] = this.currentGrid.actions[key];
          return r;
        }, {});
      return result;
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/show/actions";
</style>
