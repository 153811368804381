<template lang="pug">
.sub-menu(v-if="isActive && component")
  component(:is="getComponent()", @update-field="updateField", :parentData="{ equipment: item, attr: attr }")
</template>

<script>
import subMenuComponents from "./sub_menu_components/index";

export default {
  components: {
    subMenuComponents,
  },

  props: {
    item: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      isActive: false,
      attr: {},
      component: null,
    };
  },

  computed: {},

  watch: {
    attr(newVal, oldVal) {
      if (newVal) {
        // console.log(this.item, newVal)
        this.component = newVal.component;
      }
    },
  },

  created() {},

  beforeMount() {},

  methods: {
    toggle(attr) {
      this.attr = attr;
      this.isActive = !this.isActive;
    },

    getComponent() {
      return subMenuComponents[this.component];
    },

    updateField(data) {
      this.$emit("update-field", data);
    },
  },
};
</script>

<style lang="scss"></style>
