export default {
  executor_changing: "can_change_executor",
  reopen: "can_reopen",
  service_changing: "can_change_service",
  specific_entity_excel: "can_specific_entity_excel",
  estimate_export: "can_estimate_export",
  add_managers: "can_add_manager",
  add_estimators: "can_add_estimator",
  add_usnzs: "can_add_usnz",
};
