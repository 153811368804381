import Vue from "vue";

Vue.mixin({
  methods: {
    watchSimpleFieldParents() {
      if (this.watch && this.watch["parents"] && this.watch["parents"].length > 0) {
        this.watch["parents"].forEach(el => {
          if (el["watch_selected_items_form"]) {
            this.$watch(
              () => {
                let form_field = this.currentForm[el.parent];
                if (form_field) {
                  return form_field["selected_items"];
                }
              },
              (newValue, oldValue) => {
                if (newValue !== undefined) {
                  this.setWatchParams(el, newValue);
                }
              },
              {
                deep: true,
              },
            );
          } else {
            this.$watch(
              () => {
                let form_field = this.currentForm[el.parent];
                if (form_field) {
                  return form_field["field"];
                }
              },
              (newValue, oldValue) => {
                if (!(this.value && this.value.length > 0)) {
                  let oldVal = this.valueIsObjectOrNot(oldValue);
                  let newVal = this.valueIsObjectOrNot(newValue);

                  if ((oldVal && newVal && oldVal !== newVal) || (!oldVal && newVal) || (oldVal && !newVal)) {
                    this.setWatchParams(el, newVal);
                  }
                }
              },
            );
          }
        });
      }
    },

    setWatchParams(el, val, params = {}) {
      params[el.parent] = val;

      let dependencies = el["dependencies"];
      if (dependencies && dependencies.length > 0) {
        dependencies.forEach(dependency => {
          let another_parent = this.currentForm[dependency];
          if (another_parent) {
            let val = this.valueIsObjectOrNot(another_parent["field"]);
            params[dependency] = val;
          }
        });
      }

      this.loadValue(params, el["parent_params"]);
    },

    loadValue(params, parent_params) {
      if (Object.keys(parent_params).length > 0) {
        this.$backend
          .index(this.$store.state.paths[parent_params["path"]] + parent_params["action"], { params: params })
          .then(({ data }) => {
            // this.currentField = data[parent_params['result_key']]
            if (parent_params["fn"]) {
              this[parent_params["fn"]](data);
            } else {
              this.currentField = data[parent_params["result_key"]];
            }
          })
          .catch(error => {
            console.log(error);
            if (error.response) {
              this.reLogin(error.response.status);
            }
            this.error = true;
          });
      }
    },
  },
});
