<template lang="pug">
div
  form-header(
    v-if="data",
    ref="form-header",
    :parentData="{ title: data.form_header.title + ' № ' + row.id, title_icon: 'show_header.svg' }"
  )

  div(v-for="block in data['blocks']", :class="['show-card-info ' + block.class]")
    .row-info-parts(v-for="part in block.parts")
      .spacer(v-if="block.spacer")
      .row-info-field(v-for="field in part")
        span.row-label {{ field.label }}
        span.row-value(v-if="field.type === 'description'", v-html="row[field.value]")
        span.row-value(v-else) {{ row[field.value] || "-" }}
</template>

<script>
import formHeader from "../../../shared/forms/formHeader";

export default {
  components: {
    formHeader,
  },

  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      // row: this.parentData.row,
      // data: this.parentData.data,
    };
  },

  computed: {
    row() {
      return this.parentData.row;
    },

    data() {
      return this.parentData.data;
    },
  },

  methods: {
    // refreshRow(row) {
    //   this.row = row
    // },
  },
};
</script>
