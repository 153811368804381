import Vue from "vue";
import prints_css from "../../assets/styles/prints";

Vue.mixin({
  computed: {
    theme() {
      return this.$store.state.theme.value;
    },

    locales() {
      return this.$store.state.locales;
    },

    default_locale() {
      return this.$store.state.account.default_locale;
    },

    current_locale() {
      return this.current_user ? this.current_user.locale || this.default_locale : this.default_locale;
    },

    current_user() {
      return this.$store.state.account.current_user;
    },

    auth_token() {
      return this.$store.state.account.auth_token;
    },

    allDataLoaded() {
      return !this.filter_data_loading;
    },

    report_id_waiting() {
      return localStorage.getItem("report_id_waiting");
    },

    notifies() {
      return this.locales["notifies"][this.current_locale];
    },
  },

  methods: {
    loadDefaultLocale(params = {}) {
      this.$backend
        .index(this.$store.state.paths["default_locale"], { params: params })
        .then(({ data }) => {
          this.$store.commit("setDefaultLocale", data);
          this.$root.$i18n.locale = data;
        })
        .catch(error => {
          console.log(error);
        });
    },

    loadDefaultLocalesData(params = {}) {
      return this.$backend
        .index(this.$store.state.paths["default_locales"], { params: params })
        .then(({ data }) => {
          if (data && Object.keys(data).length > 0) {
            this.$store.commit("addLocales", data);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    destroyIntervals() {
      this.intervals.forEach(clearInterval);
      this.intervals = [];
    },

    setTheme(val = undefined) {
      // let theme = val || this.current_user.theme
      // this.$store.commit('changeTheme', theme)
      // document.documentElement.setAttribute('theme', theme)

      this.$backend
        .index(this.$store.state.paths["user"] + "/current_theme", { params: {} })
        .then(({ data }) => {
          let theme = val || data;
          this.$store.commit("changeTheme", theme);
          document.documentElement.setAttribute("theme", theme);
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        });
    },

    print(attr) {
      if (attr && attr.html_element_id) {
        let id = attr.html_element_id;
        let css = prints_css[attr.css_key];
        let title = attr.html_title;
        this.$PrintPlugin(id, css, title);
      }
    },

    temporaryFixSafariPopupBug(event, parent_id) {
      let parent = document.getElementById(parent_id);

      if (event === "open") {
        let field = document.getElementById(this.name);
        field.addEventListener(
          "click",
          e => {
            if (e.target.classList.contains("vdatetime")) {
              //console.log(event);

              if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
                //console.log("It's Safari");
              } else {
                //console.log("Not Safari");
              }

              parent.style["overflow-y"] = "initial";
              parent.style["overflow-x"] = "initial";
            }
          },
          false,
        );
      } else if (event === "close") {
        //console.log(event);

        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
          console.log("It's Safari");
        } else {
          console.log("Not Safari");
        }

        parent.style["overflow-y"] = "auto";
        parent.style["overflow-x"] = "hidden";
      }
    },
  },
});
