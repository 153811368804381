<template lang="pug">
div
  component(
    v-for="field in fields",
    :key="field.name",
    :is="getFieldComponent(field.type)",
    @fields-loaded="fieldsLoaded",
    :parentData="{ grid: grid, data: getFieldData(field) }"
  )
</template>

<script>
import fields from "../forms/fields";

export default {
  components: {
    fields,
  },

  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      action: this.parentData.action,
      row: this.parentData.row,
      fields: this.parentData.data,
      grid: this.parentData.grid,

      loading_fields: [],
      loading: true,
    };
  },

  created() {
    this.fieldsLoaded();
  },

  methods: {
    getFieldComponent(key) {
      return fields[key];
    },

    getFieldData(field) {
      return this.fields.filter(f => f === field);
    },

    dataHasLoaded() {
      return !this.loading;
    },

    fieldsLoaded(field = undefined) {
      if (this.loading_fields.length === 0) {
        this.loading_fields = this.fields
          .filter(f => this.$store.state.fields_with_options.includes(f.type))
          .map(f => [f.type, f.name]);
      }

      if (field) {
        this.loading_fields = this.loading_fields.filter(f => f.sort().toString() !== field.sort().toString());
      }

      if (this.loading_fields.length === 0) {
        this.loading = false;
      }

      this.$emit("loading-form", this.loading);
    },
  },
};
</script>

<style lang="scss"></style>
