<template lang="pug">
.form-field.form-checkbox
  q-checkbox(
    dark,
    :value="value",
    :label-slot="false",
    @input="onChange(item, $event)",
    @clear="onChange(item, $event)"
  )
</template>

<script>
export default {
  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      item: this.parentData.item,
      value_key: this.parentData.value_key,
      value: this.parentData.value || false,
      selected_items: this.parentData.selected_items,
    };
  },

  computed: {},

  created() {},

  beforeMount() {},

  methods: {
    onChange(item, e) {
      this.value = e;

      let data = {
        id: item.id,
        value_key: this.value_key,
        value: this.value,
      };

      this.$emit("set-selected-items", data);
    },
  },
};
</script>

<style lang="scss"></style>
