import Vue from "vue";

Vue.mixin({
  computed: {},

  methods: {
    addWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
});
