<template lang="pug">
div
  .form-field-label
    span {{ label }}
    span.form-field-require-tag(v-show="required", style="color: red", :title="notifies.required_field")
      | *

  div(:class="[currentFieldIsInvalid ? main_class + ' valid-error' : main_class]")
    .file-drag-drop-area(:id="`file-field-${name}`", ref="fileInput", @click="openFileInput")
      img.file-field-icon(:src="require('../../../../assets/icons/form/fields/file_field_icon.svg')")
      span.drop-files {{ field_locales.sublabel }}
      q-file(
        v-show="false",
        filled,
        v-model="currentField",
        :multiple="multiple",
        :label="label",
        :for="`file-input-${name}`"
      )

  span.valid-error-message(v-if="currentFieldIsInvalid") {{ valid_error_message }}

  .attached-items-container(v-if="filesExist(currentField)")
    .attached-item(v-for="(file, key) in currentField")
      .row.items-center.full-height.full-width
        .col-3.display-flex-centered
          img.item-img(
            v-if="['image/jpg', 'image/gif', 'image/jpeg', 'image/png'].includes(file.type)",
            :ref="'preview-img' + parseInt(key)"
          )

          .doc-box(v-else)
            inline-svg(:src="require('../../../../assets/icons/doc.svg')")
        .col-7.col-caption
          .row
            .col
              span.item-caption {{ file.name }}
                q-tooltip(anchor="top middle", self="bottom middle") {{ file.name }}
          .row
            .col
              span.file-size ({{ fileSize(file) }})
        .col-2.text-center
          q-icon.item-close(@click.stop="removeFile(file)", name="close")

  existing-files-area(
    v-if="filesExist(value)",
    :parentData="{ files: value, row: row, path: path, download_label: download_label }"
  )
</template>

<script>
import existingFilesArea from "../../helpers/existingFilesArea";
import { BytesFormatter } from "../../../../services/bytesFormatter";

export default {
  components: {
    existingFilesArea,
  },

  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      method: this.parentData.method,
      method_limit: this.parentData.method_limit,
      grid: this.parentData.grid,
      row: this.parentData.row,
      multiple: this.parentData.data[0].multiple || false,
      // value: this.parentData.data[0].value,
      label: this.parentData.data[0].label,
      name: this.parentData.data[0].name,
      available_format: this.parentData.data[0].available_format,
      depend_from: this.parentData.data[0].depend_from || {},
      // required: this.parentData.data[0].require,
      req: this.parentData.data[0].require,
      valid_error_message: null,
      main_class: "form-field form-file",
      path: this.parentData.path,

      watch: this.parentData.data[0].watch || {},
      download_label: this.parentData.data[0].download_label || "MISSING TEXT",
      image_url: "",

      image: false,

      dragAndDropCapable: false,
      files: [],
      uploadPercentage: 0,
    };
  },

  computed: {
    required: {
      get() {
        return this.req;
      },
      set(value) {
        this.req = value;
      },
    },

    value() {
      return this.parentData.data[0].value;
    },

    currentField: {
      get() {
        let form_field = this.currentForm[this.name];
        if (form_field) {
          if (form_field["field"]) {
            return form_field["field"].map(field => field["label"]);
          } else {
            return undefined;
          }
        } else {
          return undefined;
        }
      },
      set(value) {
        this.checkAvailableFormat(value);
      },
    },

    fieldDependency() {
      return this.checkDependency();
    },

    field_locales() {
      return this.locales.file_field[this.current_locale];
    },

    attached_files() {
      return this.currentField || [];
    },
  },

  watch: {
    fieldDependency(newVal, oldVal) {},
  },

  created() {
    this.resetField();
    this.watchSimpleFieldParents();
  },

  mounted() {
    let input = document.getElementById(`file-field-${this.name}`);
    this.dragAndDropCapable = this.determineDragAndDropCapable();
    if (this.dragAndDropCapable) {
      ["drag", "dragstart", "dragend", "dragover", "dragenter", "dragleave", "drop"].forEach(
        function (evt) {
          input.addEventListener(
            evt,
            function (e) {
              e.preventDefault();
              e.stopPropagation();
            }.bind(this),
            false,
          );
        }.bind(this),
      );
      input.addEventListener(
        "drop",
        function (e) {
          let files = this.multiple ? e.dataTransfer.files : [e.dataTransfer.files[0]];
          files = Object.values(files);
          this.checkAvailableFormat(files);
        }.bind(this),
      );
    }
  },

  methods: {
    setRequire(val) {
      this.required = val;
      let result = {};
      if (val) {
        this.resetField();
      } else {
        let result = {};
        result["invalid"] = false;
        this.$store.commit("updateFormField", { grid_name: this.grid, field: this.name, value: result });
      }
    },

    checkAvailableFormat(value) {
      if (this.available_format && this.available_format.types && this.available_format.types.length > 0) {
        if (this.multiple) {
          value.forEach(f => {
            if (!this.available_format.types.includes(f.type)) {
              let msg = this.notifies.invalid_file_extension + this.available_format.names.join(", ");
              this.$q.notify(msg);
            }
          });
        } else {
          let val = value[0] || value;
          if (!this.available_format.types.includes(val.type)) {
            let msg = this.notifies.invalid_file_extension + this.available_format.names.join(", ");
            this.$q.notify(msg);
          } else {
            this.addFile(val);
          }
        }

        if (this.multiple) {
          let val = value.filter(f => {
            return this.available_format.types.includes(f.type);
          });
          this.addFile(val);
        }
      } else {
        this.addFile(value);
      }
    },

    filesExist(array) {
      return array && array.filter(el => el).length > 0;
    },

    openFileInput() {
      document.getElementById(`file-input-${this.name}`).click();
    },

    determineDragAndDropCapable() {
      let div = document.createElement("div");
      return (
        ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
        "FormData" in window &&
        "FileReader" in window
      );
    },

    getImagePreviews() {
      if (this.currentField && this.currentField.length > 0) {
        this.currentField.forEach((file, i) => {
          if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
            let reader = new FileReader();
            reader.addEventListener(
              "load",
              function () {
                this.$refs["preview-img" + parseInt(i)][0].src = reader.result;
              }.bind(this),
              false,
            );
            reader.readAsDataURL(file);
          }
        });
      }
    },

    showImage(url) {
      this.image_url = url;
      this.image = true;
    },

    isImage(value) {
      if (value) {
        return ["image/jpeg", "image/png"].includes(value.content_type);
      } else {
        return false;
      }
    },

    serializeFile(file) {
      return { label: file, value: { name: file.name, file: file, type: file.type } };
    },

    addFile(value) {
      let files = this.multiple ? this.attached_files.map(file => this.serializeFile(file)) : [];
      let result = {};
      let arr = [value].flat();

      arr.forEach(file => {
        let val = this.serializeFile(file);
        files = files.concat([val]);

        result["field"] = files;
        result["invalid"] = this.invalid(files);

        this.$store.commit("updateFormField", { grid_name: this.grid, field: this.name, value: result });
      });

      setTimeout(() => {
        this.getImagePreviews();
      }, 500);
    },

    removeFile(file) {
      const new_files = this.attached_files.filter(f => f.name != file.name);
      const list = new_files.map(f => this.serializeFile(f));
      const result = { field: list, invalid: this.invalid(list) };

      this.$store.commit("updateFormField", { grid_name: this.grid, field: this.name, value: result });

      this.getImagePreviews();
    },

    resetField() {
      let result = {};
      result["invalid"] = this.invalid();
      this.$store.commit("updateFormField", { grid_name: this.grid, field: this.name, value: result });
    },

    invalid(val = undefined) {
      if (this.required) {
        if (val && val.length > 0) {
          this.valid_error_message = null;
          return false;
        } else {
          this.valid_error_message = this.notifies.not_empty;
          return true;
        }
      } else {
        this.valid_error_message = null;
        return false;
      }
    },

    fileSize(file) {
      const bytesFormatter = new BytesFormatter(file.size);

      return bytesFormatter.convert();
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/styles/forms/fields/file";

.attached-items-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 2.3%;

  .display-flex-centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .attached-item {
    width: 21em;
    height: 5.857142857142857em;
    vertical-align: top;
    display: inline-block;
    background: var(--file-card-color);
    border-radius: 10px;
    border: 1px solid #d0cece;
    margin-bottom: 10px;

    %preview-box {
      width: 4em;
      height: 4em;
      border-radius: 7px;
    }

    &:nth-of-type(2n) {
      float: right;
    }

    .col-centered {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .col-caption {
      padding-right: 0;
      padding-left: 0.5714em;
    }

    .item-img {
      @extend %preview-box;

      object-fit: cover;
    }

    .item-caption {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-transform: capitalize;
      padding-bottom: 0.5em;
      color: var(--field-input-color);
    }

    .item-close {
      cursor: pointer;
      color: var(--field-cancel-icon-color);
      margin: auto;
    }

    .doc-box {
      @extend %preview-box;

      background: var(--file-card-box-color);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
    }

    .file-size {
      color: #78839c;
    }
  }
}
</style>
