import i18n from "../plugins/vue-18n";

export class BytesFormatter {
  constructor(bytes, decimals = 0) {
    this.bytes = bytes;
    this.decimals = decimals;
  }

  convert() {
    if (this.bytes === 0) return i18n.tc(`message.zeroBytes`);

    const ksize = 1024;
    const dm = this.decimals < 0 ? 0 : this.decimals;
    const sizes = ["bytes", "kb", "mb", "gb", "tb", "pb"];
    const index = Math.floor(Math.log(this.bytes) / Math.log(ksize));
    const sizeName = sizes[index];
    const sizeNameTranslated = i18n.tc(`message.${sizeName}`);
    const convertedSize = parseFloat((this.bytes / Math.pow(ksize, index)).toFixed(dm));
    const output = `${convertedSize} ${sizeNameTranslated}`;

    return output;
  }
}
