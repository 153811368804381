<template lang="pug">
.login-component
  video(autoplay, muted, loop)
    source(type="video/mp4", :src="require('../../assets/videos/amelia-background-video.mp4')")

  .login-background

  .q-pa-md.row.items-start.justify-center
    #login-card
      .login-logo.row.justify-center(style="margin-top:150px !important")
        inline-svg(:src="require('../../assets/icons/login/login-logo.svg')")

      .login-title.row.justify-center(style="margin-top:150px !important")
        span В связи с проведением
        span технических работ
        span cайт временно не доступен

</template>

<script>
export default {
  data: function () {
    return {
      loading: true,
      login_locales_loading: true,
    };
  },

  created() {
    this.loadDefaultLocale();
    this.loadDefaultLocalesData().finally(() => {
      this.login_locales_loading = false;
    });
  },

  mounted() {
    setTimeout(() => {
      this.loading = false;
      this.$emit("set-loading", false);
    }, 500);
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/login.scss";
</style>
